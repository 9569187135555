<template>
  <div class="admin-panel-links-parent">
    <div class="has-scrollbars2">
      <!-- WINDOW CASE -->
      <div class="d-flex h-100">
        <!-- WINDOW 1 -->
        <DebugWindow
          class="mb-0 w-50"
          :init-mode="0"
          :is-admin="true"
        />

        <!-- WINDOW 2 -->
        <DebugWindow
          class="w-50"
          :init-mode="2"
          :is-admin="true"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import DebugWindow from "../../../../Debug-v1/DebugWindow.vue";

  export default {
    components: {
      DebugWindow,
    },
    data() {
      return {};
    },
  };
</script>

<style lang="scss" scoped>
  .admin-panel-links-parent {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    pointer-events: auto;
    // border: 1px dashed yellow;
    border-bottom: 1px solid rgba(white, 0.1);
    background-color: #18181b;
  }

  .has-scrollbars2 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
</style>
